import { AfterViewInit, Component } from '@angular/core';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements AfterViewInit {
  title = 'pluto';
  ngAfterViewInit() {
    import('src/assets/js/contact.js');
    import('src/assets/js/home.js');
    import('src/assets/js/main.js');
    import('src/assets/js/services.js');
    import('src/assets/js/pluto.js');
  }

}
