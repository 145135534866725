import { Component, OnInit } from '@angular/core';
import { InitService } from '../angularservices/initService/init.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  sideBarSet = false;
  constructor(private initService: InitService) { }
  ngOnInit() {
  }

  ngAfterViewInit() {
    if (!this.sideBarSet) {
      setTimeout(() => {
        this.initService.initSideBar();
      }, 0);
    }
    this.sideBarSet = true;
  }
}
