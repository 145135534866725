import { Component, AfterViewInit } from '@angular/core';
import { from } from 'rxjs';
import { InitService } from '../angularservices/initService/init.service';
@Component({
  selector: 'app-index',
  templateUrl: './index.component.html',
  styleUrls: ['./index.component.scss']
})
export class IndexComponent implements AfterViewInit {

  constructor(private initService: InitService) { }

  ngOnInit() {

  }

  ngAfterViewInit() {
    setTimeout(() => {
      this.initService.animateCtas();
    }, 0);
  }

}
