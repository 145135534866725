import { Component, OnInit } from '@angular/core';
import { InitService } from '../angularservices/initService/init.service';

@Component({
  selector: 'app-services',
  templateUrl: './services.component.html',
  styleUrls: ['./services.component.scss']
})
export class ServicesComponent implements OnInit {

  constructor(private initService: InitService) { }

  ngOnInit() {
  }

  ngAfterViewInit() {
    setTimeout(() => {
      this.initService.animateCtas();
    }, 0);
  }

}
