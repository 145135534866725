import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class InitService {

  constructor() { }


  _window(): any {
    return window;
  }

  initSideBar() {
    let skel = this._window().skel;
    let $ = this._window().jQuery;

    this._window().showHideNavPanel = function (event) {
      event.preventDefault();
      event.stopPropagation();
      $('body').toggleClass('navPanel-visible');

    };
    skel.breakpoints({
      wide: '(max-width: 1680px)',
      normal: '(max-width: 1280px)',
      narrow: '(max-width: 980px)',
      narrower: '(max-width: 840px)',
      mobile: '(max-width: 736px)',
      mobilep: '(max-width: 480px)'
    });

    $(function () {

      let $window = $(window),
        $body = $('body'),
        $header = $('#pluto_header'),
        $banner = $('#banner');

      // Fix: Placeholder polyfill.
      $('form').placeholder();

      // Prioritize "important" elements on narrower.
      skel.on('+narrower -narrower', function () {
        $.prioritize(
          '.important\\28 narrower\\29',
          skel.breakpoint('narrower').active
        );
      });

      // Dropdowns.
      $('#nav > ul').dropotron({
        alignment: 'right'
      });

      // Off-Canvas Navigation.

      // Navigation Button.
      $(
        '<div id="navButton" onclick="showHideNavPanel(event)">' +
        '<a href="#" class="toggle"></a>' +
        '</div>'
      )
        .appendTo($body);

      // Navigation Panel.
      $(
        '<div id="navPanel" >' +
        '<nav>' +
        $('#nav').navList() +
        '</nav>' +
        '</div>'
      )
        .appendTo($body)
        .panel({
          delay: 500,
          hideOnClick: true,
          hideOnSwipe: true,
          resetScroll: true,
          resetForms: true,
          side: 'left',
          target: $body,
          visibleClass: 'navPanel-visible'
        });

      // Fix: Remove navPanel transitions on WP<10 (poor/buggy performance).
      if (skel.vars.os == 'wp' && skel.vars.osVersion < 10) {
        $('#navButton, #navPanel, #page-wrapper')
          .css('transition', 'none');
      }

      // Header.
      // If the header is using "alt" styling and #banner is present, use scrollwatch
      // to revert it back to normal styling once the user scrolls past the banner.
      // Note: This is disabled on mobile devices.
      if (!skel.vars.mobile
        && $header.hasClass('alt')
        && $banner.length > 0) {

        $window.on('load', function () {

          $banner.scrollwatch({
            delay: 0,
            range: 0.5,
            anchor: 'top',
            on: function () { $header.addClass('alt reveal'); },
            off: function () { $header.removeClass('alt'); }
          });

        });

      }

    });



  }


  animateCtas() {
    let $ = this._window().jQuery;

    $('.from-top.delay-normal').each(function () {
      $(this).appear(function () {
        $(this).delay(150).animate({ opacity: 1, top: "0px" }, 600);
      });
    });
    $('.from-top.delay-200').each(function () {
      $(this).appear(function () {
        $(this).delay(200).animate({ opacity: 1, top: "0px" }, 600);
      });
    });
    $('.from-top.delay-600').each(function () {
      $(this).appear(function () {
        $(this).delay(600).animate({ opacity: 1, top: "0px" }, 600);
      });
    });

    $('.from-top.delay-1000').each(function () {
      $(this).appear(function () {
        $(this).delay(1000).animate({ opacity: 1, top: "0px" }, 600);
      });
    });
    $('.from-top.delay-1400').each(function () {
      $(this).appear(function () {
        $(this).delay(1400).animate({ opacity: 1, top: "0px" }, 600);
      });
    });
    $('.from-top.delay-1800').each(function () {
      $(this).appear(function () {
        $(this).delay(1800).animate({ opacity: 1, top: "0px" }, 600);
      });
    });



    $('.from-bottom.delay-normal').each(function () {
      $(this).appear(function () {
        $(this).delay(150).animate({ opacity: 1, bottom: "0px" }, 600);
      });
    });
    $('.from-bottom.delay-200').each(function () {
      $(this).appear(function () {
        $(this).delay(200).animate({ opacity: 1, bottom: "0px" }, 600);
      });
    });

    $('.from-bottom.delay-600').each(function () {
      $(this).appear(function () {
        $(this).delay(600).animate({ opacity: 1, bottom: "0px" }, 600);
      });
    });


    $('.from-bottom.delay-1000').each(function () {
      $(this).appear(function () {
        $(this).delay(1000).animate({ opacity: 1, bottom: "0px" }, 600);
      });
    });


    $('.from-bottom.delay-1400').each(function () {
      $(this).appear(function () {
        $(this).delay(1400).animate({ opacity: 1, bottom: "0px" }, 600);
      });
    });
    $('.from-bottom.delay-1800').each(function () {
      $(this).appear(function () {
        $(this).delay(1800).animate({ opacity: 1, bottom: "0px" }, 600);
      });
    });



    $('.from-left.delay-normal').each(function () {
      $(this).appear(function () {
        $(this).delay(150).animate({ opacity: 1, left: "0px" }, 600);
      });
    });
    $('.from-left.delay-200').each(function () {
      $(this).appear(function () {
        $(this).delay(200).animate({ opacity: 1, left: "0px" }, 600);
      });
    });

    $('.from-left.delay-600').each(function () {
      $(this).appear(function () {
        $(this).delay(600).animate({ opacity: 1, left: "0px" }, 600);
      });
    });

    $('.from-left.delay-1000').each(function () {
      $(this).appear(function () {
        $(this).delay(1000).animate({ opacity: 1, left: "0px" }, 600);
      });
    });

    $('.from-left.delay-1400').each(function () {
      $(this).appear(function () {
        $(this).delay(1400).animate({ opacity: 1, left: "0px" }, 600);
      });
    });
    $('.from-left.delay-1800').each(function () {
      $(this).appear(function () {
        $(this).delay(1800).animate({ opacity: 1, left: "0px" }, 600);
      });
    });



    $('.from-right.delay-normal').each(function () {
      $(this).appear(function () {
        $(this).delay(150).animate({ opacity: 1, right: "0px" }, 600);
      });
    });
    $('.from-right.delay-200').each(function () {
      $(this).appear(function () {
        $(this).delay(200).animate({ opacity: 1, right: "0px" }, 600);
      });
    });

    $('.from-right.delay-600').each(function () {
      $(this).appear(function () {
        $(this).delay(600).animate({ opacity: 1, right: "0px" }, 600);
      });
    });

    $('.from-right.delay-1000').each(function () {
      $(this).appear(function () {
        $(this).delay(1000).animate({ opacity: 1, right: "0px" }, 600);
      });
    });

    $('.from-right.delay-1400').each(function () {
      $(this).appear(function () {
        $(this).delay(1400).animate({ opacity: 1, right: "0px" }, 600);
      });
    });

    $('.fade-in.delay-normal').each(function () {
      $(this).appear(function () {
        $(this).delay(150).animate({ opacity: 1, right: "0px" }, 600);
      });
    });
    $('.fade-in.delay-1800').each(function () {
      $(this).appear(function () {
        $(this).delay(1800).animate({ opacity: 1, right: "0px" }, 600);
      });
    });



  }
}
